#__react-alert__ {
  z-index: 100000;
  position: fixed;
}
#__react-alert__ > div {
  z-index: 100000;
  top: 90px !important;
  right: 30px !important;
  left: initial !important;
}
#__react-alert__ > div > div {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 1px;
  background: var(--bg-gray-0);
  padding: 12px 16px 12px 8px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 640px) {
  #__react-alert__ > div {
    top: 70px !important;
    right: 15px !important;
  }
}

// toast_alert_container toast_alert toast_alert_success toast_alert_error
.toast_alert_container {
  top: 56px !important;
  @apply font-normal cp-text-tiny text-left;

  .toast_alert_error {
    background: var(--bg-alert-100);
    color: #fff;
    min-width: 250px;
    padding: 0.75rem 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }
  .toast_alert_success {
    background: var(--bg-green);
    color: #fff;
    min-width: 250px;
    padding: 0.75rem 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }

  .toast_alert_error > div,
  .toast_alert_success > div {
    justify-content: flex-start;
    margin: 0;
  }
}
