@mixin button-bg($bg, $border: false) {
  color: var(--text-white);
  background: $bg;
  border: 1px solid $bg;

  &.btn-outline {
    background: var(--bg-gray-0);
    border: 1px solid $bg;
    color: $bg;

    &:hover {
      border: 1px solid $bg !important;
      color: $bg;
      opacity: 0.8;
    }
  }

  &:not(:disabled) {
    &:hover {
      background: var(--bg-gray-0);
      @if $border {
        color: $border;
        border: 1px solid $border;
      } @else {
        color: $bg;
      }
    }
    &:active {
      outline: 1px solid $bg;
    }
  }
}

.btn {
  font-family: Inter, sans-serif;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: var(--text-gray-40);
  position: relative;
  white-space: nowrap;
  transition: all 0.3s ease;

  padding: 6px 12px;
  height: 32px;
  @apply cp-text-sm leading-normal;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    margin-right: 0.25rem;
    svg {
      margin-right: 0;
    }
  }

  &.btn-box {
    padding: 7px;
    height: 34px;

    svg {
      margin-right: 0;
    }

    &.btn-medium {
      padding: 12px;
      @apply cp-text-base;
    }

    &.btn-large {
      padding: 14px;
    }
  }

  &.btn-medium {
    padding: 9px 16px;
    height: auto;
    @apply cp-text-tiny leading-normal;
  }

  &.btn-large {
    padding: 11px 20px;
    height: auto;
    @apply cp-text-md leading-normal;
  }

  &.btn-primary {
    @include button-bg(var(--text-gray-100));
  }
  &.btn-gray {
    @include button-bg(var(--bg-gray-10));
    @apply text-cp-gray-100;
  }
  &.btn-secondary {
    @include button-bg(var(--bg-primary-100));
  }
  &.btn-blue {
    @include button-bg(var(--bg-blue));
  }
  &.btn-danger {
    @include button-bg(var(--bg-red));
  }
  &.btn-success {
    @include button-bg(var(--bg-turquoise));
  }
  &.btn-outline {
    @apply border border-solid text-cp-gray-100 border-cp-gray-20;

    &:not(:disabled) {
      &:hover {
        @apply border-cp-gray-10 dark:border-cp-gray-0;
      }

      &:active {
        outline: 1px solid var(--bg-gray-100);
      }
    }
  }

  &.btn-publish {
    padding: 3px 10px 3px 30px;
    min-width: 80px;
    justify-content: flex-start;
  }

  span.icon-wrapper {
    position: relative;
    margin-left: 10px;

    svg {
      margin-left: 10px;
    }

    &:before {
      position: absolute;
      left: 0;
      top: -3px;
      width: 1px;
      background: rgba(#fff, 0.1);
      height: calc(100% + 5px);
      content: '';
    }
  }

  &.btn-link {
    @apply hover:text-gray-600 active:text-gray-700 text-gray-800 underline font-bold;
  }
  &.btn-link-secondary {
    @apply hover:text-gray-600 active:text-gray-700 text-gray-800 font-bold;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

// GOOGLE SIGN IN BUTTON STYLES.
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.googleSignIn {
  border: none;
  border-radius: 3px;
  background-color: #4285f4;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  border: none;
  display: flex;
  padding: 1px;
  color: #fff;
  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  }
  &:active {
    background: #3367d6;
  }
}

.googleSignIn--white {
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
  &:active {
    background: #eeeeee;
  }
}

.googleSignIn:disabled {
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.4);
  box-shadow: none;
}

.googleSignIn__text {
  padding: 11px;
  // font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  white-space: nowrap;
}
