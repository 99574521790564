.logo {
  position: relative;
  &:hover {
    .back {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .back {
    transition: 300ms;
    opacity: 0;
    position: absolute;
    background-color: #fff;
    pointer-events: none;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
