fc .fc-toolbar {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.fc .fc-toolbar .fc-prev-button,
.fc .fc-toolbar .fc-next-button {
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
}

.fc .fc-toolbar .fc-prev-button .fc-icon,
.fc .fc-toolbar .fc-next-button .fc-icon {
  color: #6e6b7b;
}

.fc .fc-toolbar .fc-prev-button {
  padding-left: 0 !important;
}

.fc .fc-toolbar .fc-toolbar-chunk:first-child {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.fc .fc-day-today {
  background: transparent !important;
  background-color: transparent !important;
}

.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
  height: 3rem;
}

.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot .fc-timegrid-slot-label-frame {
  text-align: center;
}

.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
  text-transform: uppercase;
}

.fc .fc-timegrid .fc-timegrid-divider {
  display: none;
}

.cp-unavailable {
  border-bottom: 1px solid #e4e4e4;
}

.fc-day-disabled.fc-col-header-cell,
.cp-available {
  background: none;
}

// .fc td.fc-timegrid-slot-label {
//   transform: translateY(-14px);
//   border: none;
// }

// .fc .cp-duration-15 td.fc-timegrid-slot-label {
//   transform: translateY(-7px);
// }

.fc .cp-duration-30 td.fc-timegrid-slot-label {
  transform: translateY(-14px);
}

.fc .cp-duration-60 td.fc-timegrid-slot-label {
  transform: translateY(-25px);
}

// .fc td.fc-timegrid-slot-label[data-time='00:00:00'] {
//   display: none;
// }

.fc .fc-scrollgrid-shrink {
  border: 0;
}

.fc .fc-timegrid-slot-minor {
  border: 0;
}

.fc .cp-duration-15 .fc-timegrid-slot {
  height: 18px !important;
  line-height: 18px !important;
  border-left: none;
}

.fc .cp-duration-30 .fc-timegrid-slot {
  height: 24px !important;
  border-left: none;
}

.fc .cp-duration-60 .fc-timegrid-slot {
  height: 36px !important;
  border-left: none;
}

.fc .fc-bg-event.cp-available,
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background: none;
}

.cp-calendar-event {
  opacity: 0.65;
}

// .fc-col-header,
// .fc .fc-timegrid .fc-scrollgrid-section .fc-col-header-cell,
// .fc .fc-timegrid .fc-scrollgrid-section .fc-timegrid-axis {
//   border: none;
// }

// .fc-theme-standard .fc-scrollgrid {
//   border: none !important;
// }

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: initial !important;
}

// .fc-theme-standard td {
//   border: 1px solid #e2e2e2 !important;
// }

.fc-timegrid-cols {
  border: 1px solid var(--bg-fc-border-color, rgba(255, 255, 255, 0));
}

// .fc .fc-bg-event.cp-unavailable {
//   background-image: repeating-linear-gradient(
//     -26.16deg,
//     rgba(255, 255, 255, 0),
//     rgba(255, 255, 255, 0.1) 8px,
//     white 8px,
//     white 16px
//   );
//   opacity: 1;
//   background-color: #f3f4f4;
// }

.fc-timegrid-body,
.fc .fc-scrollgrid-section table,
.fc .fc-timegrid .fc-daygrid-body,
.fc-media-screen .fc-timegrid-cols > table {
  width: 100% !important;
}

.fc-media-screen .fc-timegrid-cols {
  top: -1px !important;
}

.fc .fc-highlight {
  background: rgba(252, 239, 213, 0.6) !important;
}

// .fc-timegrid-slots {
//   tr:nth-child(4n) {
//     // border-bottom: 1px solid #e2e2e2;
//     border-bottom: 1px solid red;
//   }
// }

// .fc-theme-standard .fc-scrollgrid {
//   border: none !important;
// }

// .fc-theme-standard td,
// .fc-theme-standard th {
//   border: none !important;
// }

// .fc tbody td,
// .fc thead th {
//   border: none !important;
// }

// .fc-theme-standard td,
// .fc-theme-standard th {
//   border-color: #e2e2e2 !important;
// }

.fc .fc-bg-event.cp-unavailable {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: var(--fc-border-color, rgba(255, 255, 255, 0));
  background-color: rgba(255, 255, 255, 0);
  opacity: 0.7;
  background-image: url("data:image/svg+xml,%3Csvg width='71' height='71' viewBox='0 0 71 71' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2_9)'%3E%3Cmask id='mask0_2_9' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='-729' y='-165' width='1104' height='580'%3E%3Cpath d='M-728.5 -164.5H374.5V414.5H-728.5V-164.5Z' fill='white' stroke='%23E4E4E4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2_9)'%3E%3Cpath d='M22.7883 -9.17854L-8.40005 6.142L-4.87923 13.3094L26.3091 -2.01114L22.7883 -9.17854Z' fill='%23D6D6D6' fill-opacity='0.3'/%3E%3Cpath d='M59.2197 -9.28075L-6.60982 23.0564L-3.089 30.2239L62.7405 -2.11335L59.2197 -9.28075Z' fill='%23D6D6D6' fill-opacity='0.3'/%3E%3Cpath d='M73.8745 1.31423L-6.86809 40.9771L-3.34727 48.1446L77.3953 8.48163L73.8745 1.31423Z' fill='%23D6D6D6' fill-opacity='0.3'/%3E%3Cpath d='M73.7148 19.1865L-5.93318 58.3117L-2.41236 65.4791L77.2356 26.3539L73.7148 19.1865Z' fill='%23D6D6D6' fill-opacity='0.3'/%3E%3Cpath d='M72.6506 37.5034L-0.3556 73.366L3.16522 80.5334L76.1714 44.6708L72.6506 37.5034Z' fill='%23D6D6D6' fill-opacity='0.3'/%3E%3Cpath d='M72.5192 55.362L33.9781 74.2944L37.4989 81.4618L76.04 62.5294L72.5192 55.362Z' fill='%23D6D6D6' fill-opacity='0.3'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2_9'%3E%3Crect width='71' height='71' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.fc .fc-timegrid-slot:empty:before {
  border-color: transparent !important;
  content: none;
}

.fc .fc-scrollgrid {
  border-color: transparent !important;
}

.fc-scrollgrid .fc-scrollgrid-liquid {
  border-color: transparent !important;
}

.fc .fc-timegrid .fc-scrollgrid-section .fc-col-header-cell {
  border-color: transparent !important;
}

.fc .fc-timegrid .fc-daygrid-body * {
  border-color: transparent !important;
}

.fc .fc-scrollgrid-liquid {
  border-color: transparent !important;
}

.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section > * {
  border-right: none;
}

.fc .fc-timegrid .fc-scrollgrid-section .fc-timegrid-axis {
  border: none;
}

.fc .fc-scrollgrid-section > td {
  border: none !important;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.fc-today-button {
  @apply font-semibold cp-text-tiny;
  padding: 0 !important;
  color: var(--text-gray-100) !important;
  background: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.fc-prev-button,
.fc-next-button {
  border: 1px solid #e4e4e4 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  span {
    color: #a1a1a1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fc-prev-button:hover,
.fc-next-button:hover,
.fc-today-button:hover {
  background-color: transparent !important;
  border-color: #e4e4e4 !important;
}

.fc-prev-button span {
  &:before {
    font-family: 'icomoon';
    font-size: 16px;
    content: '\e960';
  }
}

.fc-next-button span {
  &:before {
    font-family: 'icomoon';
    font-size: 16px;
    content: '\e961';
  }
}

.fc-toolbar-title {
  @apply font-semibold cp-text-lg;
}

.fc-event-title {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
}

.cp-calendar-event {
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
}

.fc-timegrid-event {
  border-radius: 0px 3px 3px 0px !important;
}

.fc-theme-standard td.fc-timegrid-slot-label[data-time='00:00:00'] {
  display: none;
}

.fc-theme-standard td.fc-timegrid-slot-label {
  transform: translateY(-11px);
}

.fc-scroller {
  height: 100% !important;
}
