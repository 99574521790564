:global {
  .step-header {
    border-bottom: 1px solid var(--bg-gray-10);
  }

  .step-bottom {
    border-top: 1px solid var(--bg-gray-10);
  }
}

.header {
  // @apply fixed w-full flex items-center justify-between;
  // height: 65px;
  // position: relative;
  z-index: 20;
  // padding-right: 20px;
  border-bottom: 1px solid var(--bg-gray-10);

  .nav_item {
    @apply flex items-center cursor-pointer;
    color: #a1a1a1;

    svg {
      max-width: 18px;
    }

    span {
      margin-left: 5px;
      &:first-child {
        margin-left: 0px;
      }
    }

    &.active_nav {
      color: #000000;

      span {
        font-weight: 500;
      }

      svg path {
        fill: #000000;
      }
    }
  }
}

.navbar_dropdown {
  width: 200px;
  right: 0;
  top: 35px;
  @apply absolute z-10 rounded shadow bg-cp-white mt-1 border border-cp-gray-10 dark:border-cp-gray-0;
}

.workspace_dropdown {
  width: 200px;
  left: 0;
  top: 44px;
  @apply absolute z-10 rounded shadow bg-cp-white mt-1 border border-cp-gray-10 dark:border-cp-gray-0;
}
