@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
html,
body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  @apply font-normal cp-text-base;
}

html {
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0%);
}

body {
  text-rendering: optimizeLegibility;
}

input,
select,
button,
textarea {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  @apply font-normal cp-text-base;
}

h1,
.h1 {
  @apply font-bold cp-text-3xl;
}

h2,
.h2 {
  @apply font-semibold cp-text-2xl;
}

h3,
.h3 {
  @apply font-semibold cp-text-xl;
}

h4,
.h4 {
  @apply font-semibold cp-text-lg;
}

h5,
.h5 {
  @apply font-semibold cp-text-md;
}

h6,
.h6 {
  @apply font-normal cp-text-base;
}

p,
.p {
  @apply font-normal cp-text-tiny;
}

small,
.small {
  @apply font-normal cp-text-sm;
}

.xsmall {
  @apply font-normal cp-text-xs;
}

b,
strong {
  @apply font-bold cp-text-base;
}
