.create-new-form {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 99;

  .button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: var(--bg-gray-100);
    border-radius: 50%;
    color: #fff;
    font-size: 19px;
    cursor: pointer;
    transition: 300ms;
    transform: rotate(0);
    outline: 0 solid rgba(0, 0, 0, 0.1);
    &:hover,
    &:active,
    &.active {
      outline: 8px solid rgba(0, 0, 0, 0.1);
      transform: rotate(45deg);
    }
  }

  .create-new-form-box {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 255px;
    height: 270px;
    padding: 1rem;
    overflow-y: auto;

    background: var(--bg-white);
    border: 1px solid var(--bg-gray-10);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 3px;

    .form-type {
      display: flex;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }

      span {
        @apply text-cp-gray-60;
      }

      &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        background: var(--bg-gray-10);
        border-radius: 6px;
        font-size: 24px;
      }
    }
  }
}

@screen md {
  .create-new-form {
    bottom: 20px;
    right: 20px;
    .create-new-form-box {
      width: 312px;
    }
  }
}
