@import 'react-circular-progressbar/dist/styles.css';
@import 'react-date-range/dist/styles.css'; // main css file
@import 'react-date-range/dist/theme/default.css'; // theme css file
@import 'react-datepicker/dist/react-datepicker.css';
@import 'mixins';
@import 'modules';
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/nord.css';
@import 'react-phone-input-2/lib/style.css';
@import 'swiper/swiper-bundle.min.css';
@import 'swiper/swiper.min.css';
@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
@import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
@import 'leaflet/dist/leaflet.css';

#crisp-chatbox {
  a[role='button'] {
    display: none !important;
  }
}
