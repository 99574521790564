.alert {
  min-width: 250px;
  padding: 0.75rem 1rem;
  background: var(--bg-white);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  p {
    font-size: 14px;
    font-weight: 500;
  }
  &.primary {
    background: var(--bg-gray-100);
    p {
      color: #fff;
    }
  }
  &.error {
    background: var(--bg-alert-100);
    p {
      color: #fff;
    }
  }
  &.success {
    background: var(--bg-green);
    p {
      color: #fff;
    }
  }
  &.info {
    background: var(--bg-primary-100);
    p {
      color: #fff;
    }
  }
}

:global {
  #__react-alert__ > div > div {
    border-radius: 0;
    background: transparent;
    padding: 0;
    box-shadow: none;
  }
}
