$header_heigth: 56px;
$sidebar_width: 224px;
$sidebar_mini_width: calc(64px + 1px); //border
$sub_sidebar_width: 200px;
$animation_time: 0.3s;

.app_layout {
  display: flex;
  // overflow: hidden;
  width: 100%;
  // height: 100%;
  height: 100vh;

  @screen md {
    // conditions
    &[data-mini_sidebar='true'] {
      .sidebar {
        padding-left: 14px;
        padding-right: 18px;
      }
      .app_aside {
        .app_sidebar {
          width: $sidebar_mini_width;
          .sidebar_collapse_button {
            svg {
              transform: rotate(180deg);
            }
          }

          .sidebar_collapse_button {
            top: 36px;
            height: 16px;
            width: 16px;
            @apply bg-cp-primary-100;
          }
        }
      }
      .app_main {
        margin-left: $sidebar_mini_width;
      }
    }

    &[data-mini_sidebar='true'][data-has_subsidebar='true'] {
      .app_main {
        margin-left: calc(#{$sidebar_mini_width} + #{$sub_sidebar_width});
      }
    }

    &[data-has_subsidebar='true'] {
      .app_aside {
        .app_subsidebar {
          width: $sub_sidebar_width;
          border-right: 1px solid var(--bg-gray-20);
        }
      }
      .app_main {
        margin-left: calc(#{$sidebar_width} + #{$sub_sidebar_width});
      }
    }
  }

  &[data-mini_sidebar='false'] {
    .single_dot {
      opacity: 0;
      visibility: hidden;
    }
  }

  // sidebar and subsidebar container
  .app_aside {
    top: 56px;
    right: 0;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 101;
    // overflow: hidden;

    // sidebar container
    .app_sidebar {
      @apply flex md:flex-col bg-cp-gray-0 relative;
      border-right: 1px solid var(--bg-gray-20);
      width: $sidebar_width;
      transition: width $animation_time;

      // overflow: hidden;
      // overflow-y: auto;
      .sidebar_collapse_button {
        right: 0;
        top: 16px;
        transform: translate(50%, 0);
        height: 24px;
        width: 24px;
        border-radius: 100%;
        cursor: pointer;
        @apply bg-cp-gray-100 flex items-center justify-center absolute;
      }
    }
    // sidebar container
    .app_subsidebar {
      width: 100%;
      @apply bg-cp-gray-0;
      // transition: width $animation_time;

      // background: blue;
    }
  }

  &.sidebarActive .app_main {
    margin-left: 0;
  }

  &.editor {
    padding-top: 56px;
  }

  // header and content container
  .app_main {
    width: 100%;
    height: 100%;
    margin-left: $sidebar_width;
    overflow-y: auto;
    position: relative;
    background: var(--bg-white);
    z-index: 100;
    display: flex;
    flex-direction: column;

    &.withAnimation {
      transition: margin $animation_time;
    }

    // header container
    .app_header {
      min-height: $header_heigth;
      position: sticky;
      z-index: 101;
      top: 0;
      background: var(--bg-white);
      border-bottom: 1px solid var(--bg-gray-20);
      display: flex;
      align-items: center;

      @screen sm {
        z-index: 100;
      }
      .app_header_container {
        width: 100%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .profile {
        display: flex;
        align-items: center;
        .avatar {
          @apply border border-cp-gray-20 dark:border-cp-gray-0;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          background: var(--bg-white);
        }
      }
    }

    // content container
    .app_content {
      @apply flex-1;
      height: 100%;
      // margin-top: $header_heigth;
    }
  }
}

.logo {
  @apply mb-10 hidden;
}

.sidebar_list {
  @apply flex-1 md:mt-16 md:space-y-5;
}

.navbar_dropdown {
  width: 160px;
  right: 0;
  top: 34px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  @apply absolute z-10 bg-cp-gray-0 mt-1 border border-cp-gray-20 divide-y divide-cp-gray-10;

  .dropdown_list {
    @apply px-4 py-3 space-y-2 text-cp-gray-100;
  }
  .dropdown_list_small {
    @apply px-4 py-3;
  }
  .dropdown_list,
  .dropdown_list_small {
    li {
      display: flex;
    }
  }
}

.single_dot {
  @apply absolute w-2 h-2 rounded-full overflow-hidden mr-1;
  top: 30px;
  left: 50%;
  transition: opacity $animation_time;
  transform: translateX(-50%);
}

.workspace {
  @apply relative;
}

.limits {
  z-index: 12;
  @apply relative;
  .limits_area {
    background: var(--bg-white);
    color: var(--text-gray-100);
    border: 1px solid var(--bg-gray-20);
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    .limit_text {
      @apply text-center flex items-center justify-center;
      padding: 7px 15px;
    }
    @apply flex items-center;
    .status {
      border-left: 1px solid var(--bg-gray-10);
      @apply flex-1 text-center flex items-center justify-center;
      padding: 7px;
      .dot {
        @apply w-2 h-2 rounded-full overflow-hidden mr-1;
      }
    }
  }

  .limit_box {
    color: var(--text-gray-100);

    @apply absolute hidden;

    &.open_box {
      @apply block;
    }

    top: 0px;
    right: 0;
    left: 0;
    padding-bottom: 6px;
    transform: translate(0, -100%);
    .arrow_box {
      @apply relative rounded-md bg-cp-white border border-cp-gray-20;
    }
    .arrow_box:after,
    .arrow_box:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .arrow_box:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: var(--text-white);
      border-width: 7px;
      margin-left: -7px;
    }
    .arrow_box:before {
      border-color: rgba(228, 228, 228, 0);
      border-top-color: var(--text-white);
      border-width: 8px;
      margin-left: -8px;
    }
  }
}

$black: #0d0d0d;
$text: #5c636a;
$gray: #21212b;
$text-active: #e3e3e3;
$closed-width: $sidebar_mini_width;

@media (max-width: 868px) {
  .app_layout {
    .app_main {
      margin-left: 0;
    }
    .sidebar {
      width: 100%;
      display: none;
    }
    .app_content {
      margin-top: 90px;
      &.no_margin {
        margin-top: 0;
      }
    }
    .workspace_inner {
      min-height: inherit;
      margin-bottom: 4px;
      .workspace_settings_image {
        display: none;
      }
    }

    &[data-mobile_sidebar='true'] {
      .app_aside {
        right: 0;
        top: 56px;
      }
      .sidebar {
        display: flex;
        position: fixed;
        z-index: 10;
        top: 56px;
        height: calc(100% - 56px);

        .toggle {
          display: none;
        }
        .close {
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      .menu {
        height: auto !important;
        flex: 1;
      }
      .header_toggler {
        .icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z' fill='black'/%3E%3C/svg%3E");
        }
      }
    }
  }
}

.sidebar {
  @apply bg-cp-gray-0 p-4 relative;
  transition-property: width;
  transition: padding $animation_time cubic-bezier(0.19, 1, 0.22, 1);
  border-right: 1px solid var(--bg-gray-20);
  width: $sidebar_width;

  .close {
    display: none;
  }
  &.app_sidebar_hovered {
    // &::after {
    //   content: '';
    //   position: absolute;
    //   height: 100%;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background-color: rgba(0, 0, 0, 0.15);
    //   z-index: 10;
    // }
  }

  .workspace_area {
    margin-bottom: 8vh;
    min-height: 132px;
    height: auto;
    display: flex;
    flex-direction: column;
    transition: opacity $animation_time cubic-bezier(0.19, 1, 0.22, 1);
  }

  .logo {
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
    a {
      color: $text-active;
      &:hover {
        color: #fff;
      }
    }
  }
  .menu {
    overflow: overlay;
    overflow-x: hidden;
    color: $text;
    li a {
      color: var(--text-gray-30);
    }
    .active {
      color: var(--text-gray-100);
    }
    .menu_icon {
      padding: 8px;
    }
  }
  .toggle {
    @apply w-6 h-6 -right-3 top-4;
    background: $black;
    color: $text;
    &:hover {
      color: $text-active;
    }
  }

  .workspace {
    @apply flex flex-col;
  }

  &.closed {
    width: $closed-width;
    .search {
      input {
        opacity: 0;
        visibility: hidden;
      }
    }
    .logo {
      // transform: scale(0.6);
      margin-left: -5px;
      [data-hide='true'] {
        opacity: 0;
        visibility: hidden;
      }
    }
    [data-hide='true'] {
      opacity: 0;
      visibility: hidden;
    }
    .menu {
      li {
        width: 5.5rem;
        overflow: hidden;
        a {
          &.active .menu_icon {
            border-radius: 3px;
            @apply bg-cp-gray-100 text-white;
          }
          span {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
    .workspace {
      .limits {
        opacity: 0;
        visibility: hidden;
      }
    }
    .toggle {
      // right: -1.25rem;
      margin-top: 2rem;
    }
    .toggle svg {
      transform: rotate(180deg);
    }
  }
}

.workspace_inner {
  @apply py-3 px-4 text-xs;
  min-height: 80px;
}

.notifications {
  @apply bg-cp-white right-0 top-6 border border-cp-gray-10 dark:border-cp-gray-0;

  border-radius: 3px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  z-index: 110;
  width: 240px;
  max-height: 288px;
  overflow-y: auto;

  // .notifications_inner {
  //   transition: transform 0.3s ease-in-out;
  //   transform: translateX(20rem);

  //   @apply absolute flex flex-col top-0 right-0 bg-cp-white;
  //   &.active {
  //     transform: translateX(0);
  //   }
  // }
}

.learn {
  @apply flex shadow-sm min-h-screen flex-col flex-auto flex-shrink-0 antialiased text-gray-800 inset-0;

  transition: visibility 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  visibility: hidden;
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 110;
  &.active {
    visibility: initial;
  }

  .learn_inner {
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(20rem);
    padding-top: 28px;
    @apply fixed flex flex-col top-0 right-0 w-80 bg-cp-white h-full border-r;
    &.active {
      transform: translateX(0);
    }
    .close {
      @apply absolute top-3 right-3 cursor-pointer;
    }

    .collapse_children {
      height: 0;
      // transition: max-height 0.1s ease-out;
      overflow: hidden;
      &.active {
        height: auto;
        transition: height 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }
}

.breadcrumb {
  display: flex;
  flex: 1;
  display: none;
}
.header_logo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  a {
    display: inline-block;
  }
  svg {
    margin: 0 auto;
  }
}

.subsidebar_list {
  display: flex;
  overflow-y: auto;
  @apply py-2;
}
.workspace_inner_secondary {
  @apply mt-3;
}

@screen md {
  .notifications {
    width: 304px;
  }
  .sidebar {
    .logo {
      display: block;
    }
  }
  .app_header {
    padding: 0 24px;
  }
  .header_toggler {
    display: none;
  }
  .breadcrumb {
    display: block;
  }
  .header_logo {
    display: none;
  }

  .app_aside {
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    right: inherit !important;
  }
  .app_subsidebar {
    width: 0;
  }
  .app_subsidebar {
    .workspace_inner {
      margin-top: 85px;
    }
  }
  .workspace_inner_secondary {
    margin-top: 85px;
  }
  .subsidebar_list {
    @apply flex-col space-y-2 text-cp-gray-40;
  }
  .subsidebar_second_list {
    @apply mt-12;
  }
}
.subsidebar_second_list {
  @apply py-3 mb-4 px-4 bg-cp-white border-b border-t border-cp-gray-20 flex items-center;
}

.header_toggler {
  cursor: pointer;
  flex: 1;
  .icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z' fill='black'/%3E%3C/svg%3E");
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-size: 100% 100%;
  }
}
