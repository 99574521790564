.tabs {
  @apply relative;

  &::after {
    content: '';
    height: 1px;
    background-color: var(--bg-gray-10);
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}
