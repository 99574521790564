.form_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--bodyBackgroundColor);
  background-repeat: var(--bodyBackgroundRepeat, no-repeat);
  background-position: var(--bodyBackgroundPosition, center);
  background-size: var(--bodyBackgroundSize, cover);
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  overflow: hidden;

  .form {
    position: relative;
    width: 60%;
    background-color: var(--formBackgroundColor, #ffffff);
    margin: 0 auto;
    margin-top: 30px;
    padding: 10px;
    .label {
      color: var(--fieldLabelColor, #000000);
      display: var(--fieldLabelHide, block);
      font-size: 10px;
    }
    .field {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      height: 16px;
      border-style: solid;
      border-top-width: var(--fieldBorderTopSize, 0);
      border-right-width: var(--fieldBorderRightSize, 0);
      border-bottom-width: var(--fieldBorderBottomSize, 0);
      border-left-width: var(--fieldBorderLeftSize, 0);
      border-color: var(--fieldBorderColor, transparent);
      background-color: var(--fieldBackgroundColor, #efefef);
      border-radius: var(--fieldBorderRadius, 2px);
    }
  }
}

.fake-form-menu {
  transform: translate(calc(-100% + 20px), 0);
}
