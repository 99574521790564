.container-fluid .input {
  max-width: inherit;
}

.layout-children {
  .cabinpanda-editor .core {
    height: calc(100vh - 80px);
  }
}

.layout-large-space {
  height: calc(100vh - 142px);
  margin-top: 142px;
  @screen lg {
    height: calc(100vh - 132px);
    margin-top: 132px;
  }
}

.layout-without-space {
  margin-top: 0;
  height: 100vh;
}
