.form-container {
  position: relative;
  max-width: 400px;
  display: block;
  width: 100%;
}

@screen md {
  .input {
    max-width: 400px;
  }
}
.input {
  width: 100%;
  // position: relative;
  // max-width: 200px;
  display: block;
  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    // background: var(--bg-gray-0);
  }
}

.input-inner {
  position: relative;
}

.input-icon {
  input {
    padding-right: 40px;
  }
  .icon {
    display: flex;
    width: 20px;
  }
}

.textarea {
  @apply block appearance-none placeholder-cp-gray-30 border border-cp-gray-20 rounded-md w-full py-3 px-2 text-cp-gray-100 focus:outline-none focus:border-cp-gray-80 focus:text-cp-gray-60;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  border-radius: 3px;
  position: relative;
  max-height: 100px;
}

.input-default {
  @apply block appearance-none placeholder-cp-gray-30 border border-cp-gray-20 rounded-md w-full px-2 text-cp-gray-100 focus:outline-none focus:border-cp-gray-80 focus:text-cp-gray-60;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  border-radius: 3px;
  position: relative;
  height: 32px;
  background: var(--bg-white);

  &:disabled {
    background: #fbfbfb;
  }
}

@screen md {
  .input {
    max-width: 400px;
  }
  .input-default {
    height: 40px;
  }
}

.strip-input {
  display: flex;
  align-items: center;
  > div {
    flex: 1;
  }
}

.input-small {
  height: 32px;
}

.input-prefix {
  @apply overflow-hidden flex;
  .input-default {
    @apply rounded-tl-none rounded-bl-none;
  }
  .prefix {
    @apply bg-cp-gray-0 px-2 flex items-center  border-l border-t border-b rounded-md rounded-tr-none rounded-br-none border-cp-gray-20;
  }
}

//Datepicker Style
.react-datepicker__navigation--next {
  right: 125px !important;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0rem !important;
}
.react-datepicker__header {
  background-color: #000000;
}
.react-datepicker__current-month {
  color: #fff;
}
.react-datepicker__day-name {
  color: #fff;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: #000000 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #000000;
}
.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  border-bottom-color: #000000 !important;
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: #3f3f3f8f !important;
}
//Datepicker Style
.custom-datepicker {
  @apply md:ml-5;
  position: relative;
  &::before {
    font-family: 'icomoon' !important;
    content: '\e904';
    position: absolute;
    right: 8px;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    font-size: 18px;
    pointer-events: none;
  }
  input {
    @apply rounded border border-cp-gray-20 px-2 h-10 text-cp-gray-40 outline-none;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    width: 120px;
  }
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  height: 14px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #7d7e7e;
  text-transform: capitalize;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &.disabled {
    color: #bfbfbf;
    cursor: not-allowed;
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 0px;
    background-color: #fff;
    border: 2px solid #a1a1a1;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  input:checked ~ .checkmark {
    border: 2px solid #000000;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.upload {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.upload input[type='file'] {
  opacity: 0;
  text-indent: -999em;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  top: 0;
  cursor: pointer;
}

textarea {
  min-height: 54px;
}

.rich-text {
  .rich-text-area {
    max-width: 400px;
    .ql-toolbar,
    .ql-container {
      border-color: var(--border-gray-20);
      border-radius: 2px;
    }
    .ql-toolbar {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .ql-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .ql-editor {
      max-height: 200px;
      background: var(--bg-white);
    }
  }
}

.ql-snow .ql-tooltip {
  z-index: 10;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--text-gray-100) !important;
}
.ql-snow .ql-stroke {
  stroke: var(--text-gray-100) !important;
}
