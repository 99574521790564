.inBuilder {
  position: absolute;
  left: 64px;
  top: 57px;
  right: 0;
  font-size: 13px;
}

.hiddenInput {
  // display: none;
  visibility: hidden;
}
.copyButton {
  position: absolute;
  bottom: 10px;
  right: 40px;
  z-index: 10;
}
