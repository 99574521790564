.leaflet-control-layers-toggle {
  background-image: url(https://cbn.li/js/layers.png);
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(https://cbn.li/js/layers-2x.png);
}

.leaflet-default-icon-path {
  background-image: url(https://cbn.li/js/marker-icon.png);
}

.leaflet-control-attribution {
  display: none !important;
}

.geoip {
  .leaflet-container {
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
  }
}
