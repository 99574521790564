.badged {
  text-decoration: none;
  border-radius: 3px;
  padding: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  border: 1px solid var(--bg-gray-20);
  background: var(--bg-gray-0);
  letter-spacing: -0.5px;
  &-success {
    color: var(--text-success-100);
    border-color: var(--success-100);
  }
  &-alert {
    color: var(--text-red);
    border-color: var(--red);
  }
  &-warning {
    color: var(--text-primary-100);
    border-color: var(--primary-100);
  }
  &-primary {
    color: #ffffff;
    border-color: var(--primary-100);
    background: var(--bg-primary-100);
  }
  &-dark {
    color: #ffffff;
    border-color: var(--gray-100);
    background: var(--bg-gray-100);
  }
  &-small {
    border-radius: 3px;
    padding: 3px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
  }
}
