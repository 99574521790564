.loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 36px;
  width: 36px;
  margin: -20px 0 0 -20px;
  border: 2px var(--border-gray-10) solid;
  border-top: 2px var(--border-gray-100) solid;
  border-radius: 50%;
  -webkit-animation: spin2 1s infinite linear;
  animation: spin2 1s infinite linear;

  &.small {
    height: 24px;
    width: 24px;
    margin: -12px 0 0 -24px;
  }
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}
