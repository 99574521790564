:root {
  --text-gray-100: #000000;
  --text-gray-80: #363636;
  --text-gray-60: #656565;
  --text-gray-40: #a1a1a1;
  --text-gray-30: #c1c1c1;
  --text-gray-20: #e4e4e4;
  --text-gray-10: #f1f1f1;
  --text-gray-0: #fafafa;
  --text-primary-100: #f2b02f;
  --text-primary-50: #f5b377;
  --text-primary-20: #fcefd5;
  --text-primary-10: #fef7ea;
  --text-primary-0: #fcf1e8;
  --text-success-100: #008556;
  --text-success-50: #d6f3e2;
  --text-success-30: #f3fbf6;
  --text-alert-100: #de1c22;
  --text-alert-50: #f9d3d4;
  --text-white: #ffffff;
  --text-red: #dc2f02;
  --text-green: #27ae60;
  --text-blue: #0070f3;
  --text-turquoise: #55d6d2;

  --bg-gray-100: #000000;
  --bg-gray-80: #363636;
  --bg-gray-60: #656565;
  --bg-gray-40: #a1a1a1;
  --bg-gray-30: #c1c1c1;
  --bg-gray-20: #e4e4e4;
  --bg-gray-10: #f1f1f1;
  --bg-gray-0: #fafafa;
  --bg-primary-100: #f2b02f;
  --bg-primary-50: #f5b377;
  --bg-primary-20: #fcefd5;
  --bg-primary-10: #fef7ea;
  --bg-primary-0: #fcf1e8;
  --bg-success-100: #008556;
  --bg-success-50: #d6f3e2;
  --bg-success-30: #f3fbf6;
  --bg-alert-100: #de1c22;
  --bg-alert-50: #f9d3d4;
  --bg-white: #ffffff;
  --bg-red: #dc2f02;
  --bg-green: #27ae60;
  --bg-blue: #0070f3;
  --bg-turquoise: #55d6d2;

  --border-gray-100: #000000;
  --border-gray-80: #363636;
  --border-gray-60: #656565;
  --border-gray-40: #a1a1a1;
  --border-gray-30: #c1c1c1;
  --border-gray-20: #e4e4e4;
  --border-gray-10: #f1f1f1;
  --border-gray-0: #fafafa;
  --border-primary-100: #f2b02f;
  --border-primary-50: #f5b377;
  --border-primary-20: #fcefd5;
  --border-primary-10: #fef7ea;
  --border-primary-0: #fcf1e8;
  --border-success-100: #008556;
  --border-success-50: #d6f3e2;
  --border-success-30: #f3fbf6;
  --border-alert-100: #de1c22;
  --border-alert-50: #f9d3d4;
  --border-white: #ffffff;
  --border-red: #dc2f02;
  --border-green: #27ae60;
  --border-blue: #0070f3;
  --border-turquoise: #55d6d2;

  --fc-page-bg-color: #ffffff;
  --fc-border-color: #e2e2e2;
}

html.dark {
  &:root {
    --text-gray-100: #ffffff;
    --text-gray-80: #bcbcbc;
    --text-gray-60: #ffffff;
    --text-gray-40: #e4e4e4;
    --text-gray-30: #9b9b9b;
    --text-gray-20: #121820;
    --text-gray-10: rgb(31 41 55);
    --text-gray-0: rgb(27 39 53);
    --text-primary-100: #f2b02f;
    --text-primary-50: #f5b377;
    --text-primary-20: rgb(27 39 53);
    --text-primary-10: #fef7ea;
    --text-primary-0: #fcf1e8;
    --text-success-100: #008556;
    --text-success-50: #d6f3e2;
    --text-success-30: #f3fbf6;
    --text-alert-100: #de1c22;
    --text-alert-50: #f9d3d4;
    --text-white: #181e27;
    --text-red: #dc2f02;
    --text-green: #27ae60;
    --text-blue: #0070f3;
    --text-turquoise: #55d6d2;

    --bg-gray-100: #000000;
    --bg-gray-80: #363636;
    --bg-gray-60: #656565;
    --bg-gray-40: #e4e4e4;
    --bg-gray-30: #9b9b9b;
    --bg-gray-20: #121820;
    --bg-gray-10: rgb(31 41 55);
    --bg-gray-0: rgb(27 39 53);
    --bg-primary-100: #f2b02f;
    --bg-primary-50: #f5b377;
    --bg-primary-20: rgb(27 39 53);
    --bg-primary-10: #fef7ea;
    --bg-primary-0: #fcf1e8;
    --bg-success-100: #008556;
    --bg-success-50: #d6f3e2;
    --bg-success-30: #f3fbf6;
    --bg-alert-100: #de1c22;
    --bg-alert-50: #f9d3d4;
    --bg-white: #181e27;
    --bg-red: #dc2f02;
    --bg-green: #27ae60;
    --bg-blue: #0070f3;
    --bg-turquoise: #55d6d2;

    --border-gray-100: #ffffff;
    --border-gray-80: #bcbcbc;
    --border-gray-60: #ffffff;
    --border-gray-40: #e4e4e4;
    --border-gray-30: #9b9b9b;
    --border-gray-20: #121820;
    --border-gray-10: #121820;
    --border-gray-0: rgb(27 39 53);
    --border-primary-100: #f2b02f;
    --border-primary-50: #f5b377;
    --border-primary-20: rgb(27 39 53);
    --border-primary-10: #fef7ea;
    --border-primary-0: #fcf1e8;
    --border-success-100: #008556;
    --border-success-50: #d6f3e2;
    --border-success-30: #f3fbf6;
    --border-alert-100: #de1c22;
    --border-alert-50: #f9d3d4;
    --border-white: #181e27;
    --border-red: #dc2f02;
    --border-green: #27ae60;
    --border-blue: #0070f3;
    --border-turquoise: #55d6d2;

    --fc-page-bg-color: #181e27;
    --fc-border-color: rgb(31 41 55);
  }
}

.mobile_show {
  display: none;
}
@media (max-width: 868px) {
  .mobile_hide {
    display: none !important;
  }
  .mobile_show {
    display: block !important;
  }
}

html {
  font-size: 14px !important;
}

@screen md {
  html {
    font-size: 1rem !important;
  }
}

body {
  background: var(--bg-gray-0);
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: var(--bg-gray-0);
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 0px;
  }
  color: var(--text-gray-100);
}

.box-shadow {
  box-shadow: 0px 10px 30px rgba(34, 34, 34, 0.05);
}
.sidebar-shadow {
  filter: drop-shadow(0px 10px 30px rgba(34, 34, 34, 0.05));
}

.react-datepicker-popper {
  z-index: 3;
}

.cabinpanda-editor {
  padding-top: 0;
  .cp-signature {
    canvas {
      width: 100%;
    }
  }
  .sidebar-body {
    height: calc(100% - 65px);
    min-height: auto;
  }
  .core {
    &.is-sidebar-open {
      overflow: hidden;
    }
  }
}

.preview-container {
  padding: 15px;
  background-color: #262626;
  height: 0;
  overflow: hidden;
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  border-radius: 30px;

  .cp-signature {
    canvas {
      width: 100%;
    }
  }

  .preview-wrapper,
  .code-mirror-height {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }

  .code-mirror-height {
    border-radius: 15px;
    overflow: hidden;
  }

  .preview-wrapper {
    border-radius: 15px;
    overflow: hidden;
    overflow-y: auto;
  }

  cp-core .core {
    padding: 0;
    height: auto;
  }
  cp-core .popup-form .trigger-button,
  cp-editor .popup-form .trigger-button {
    position: absolute;
    z-index: 5;
  }

  cp-core .conversational,
  cp-editor .conversational {
    position: absolute !important;
    .bottom {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .regular-form {
    padding-top: 40px !important;
  }
  .conversational-form .messages {
    height: 400px;
  }

  cp-popup {
    .popup-form {
      position: absolute;
      &.slide-center {
        height: 100%;
        &.animate {
          .trigger-button {
            bottom: 0;
          }
        }
        .trigger-button {
          bottom: -100%;
        }
      }
    }
    .popup-center-handler {
      position: absolute;
      width: 100% !important;
      .overlay {
        position: absolute;
      }
      .trigger-button {
        transition: none;
      }
    }
    .preview-wrapper {
      border-radius: 15px;
      overflow: hidden;
      overflow-y: auto;
      .popup-center-handler {
        position: relative;
      }
    }
  }
  // .core-popup {
  //   position: relative;
  //   padding: 30px 0px;
  //   min-height: 100%;
  // }
  // cp-popup {
  //   width: 100%;
  //   height: 100%;
  // }
  // .overlay {
  //   position: absolute !important;
  // }
  // .popup-form {
  //   width: 100%;
  //   height: 100%;
  //   position: relative !important;
  //   // .popup-inner {
  //   //   width: fit-content;
  //   // }
  //   &.animate {
  //     width: fit-content;
  //     margin: auto;
  //     left: auto !important;
  //     transform: none !important;
  //   }
  //   .popup-form {
  //     height: 100% !important;
  //   }
  //   .overlay {
  //     display: none;
  //   }
  //   .popup-form-wrapper {
  //     position: absolute !important;
  //     overflow-x: hidden;
  //   }
  // }
}

.submission-detail-height {
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .button-wrapper {
    position: sticky;
    bottom: 0px;
    background: var(--bg-gray-0);
    padding: 10px 20px;
    border-top: 1px solid #f1f1f1;
  }

  .submission-detail-header {
    background: var(--bg-gray-0);
    padding-top: 20px;
  }
}

.webhook-max-height {
  max-height: calc(100vh - 340px);
  overflow: auto;
}

.__json-pretty__ {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  span {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.deliveries_header {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.color-picker {
  height: 28px;
  width: 54px;
  border-radius: 6px;
  input[type='color'] {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.divider-or {
  background: var(--bg-gray-0);
  position: relative;
  width: 30px;
  height: 100%;
  display: inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 12px;
  color: rgba(101, 101, 101, var(--tw-text-opacity));
}

.webhooks-sidebar {
  top: 74px;
  @screen lg {
    top: 65px;
  }
}

.loading-dots {
  @apply inline-flex text-center items-center leading-7;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  & span {
    @apply rounded-full h-2 w-2;
    background: rgb(255, 255, 255);
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    margin: 0 2px;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.create-form-core {
  cp-core .core {
    height: initial;
  }
}

.tab {
  @apply flex flex-row border-b border-cp-gray-20 space-x-7;

  .tabItem {
    @apply relative cursor-pointer font-medium py-2 block text-cp-gray-40;

    &.active {
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        position: absolute;
        @apply bg-cp-primary-100;
      }
      @apply text-cp-gray-100;
    }
  }
}

.__react_component_tooltip {
  opacity: 1 !important;
}
.__react_component_tooltip.place-right,
.__react_component_tooltip.place-left {
  padding: 4px 6px !important;
}

.__react_component_tooltip.place-top,
.__react_component_tooltip.place-bottom {
  padding: 4px 6px !important;
}

.empty_image {
  width: 100px;
  height: 100px;
}

.swiper-container {
  .swiper-scrollbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
    background: rgba(0, 0, 0, 0.1);

    .swiper-scrollbar-drag {
      height: 100%;
      transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .swiper-wrapper {
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  }
}

// .filepond--drop-label {
//   background-color: #fefbf5;
//   font-size: 0.75rem !important;
//   padding: 6px;
//   min-height: 100px !important;
//   label {
//     width: 100%;
//     height: 100%;
//     display: flex !important;
//     align-items: center;
//     justify-content: center;
//     @apply border rounded border-dashed border-cp-gray-40;
//   }
// }

.filepond--panel-root {
  @apply bg-cp-gray-0 dark:bg-cp-white;
  // background-color: var(--bg-gray-30);
}
.filepond--drop-label {
  @apply text-cp-gray-60;
  font-size: 0.75rem !important;
  min-height: 100px !important;
  padding: 10px;
}

.filepond--drop-label label {
  @apply border rounded border-dashed border-cp-gray-40;
  height: 100%;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.filepond--credits {
  display: none !important;
}

.filepond--drop-label {
  will-change: unset !important;
}

.filepond--label-action {
  margin-left: 4px;
}

.hover-tooltip {
  font-size: 20px !important;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.SVGInline {
  display: none !important;
}

#rc-editable-input-2 {
  height: 30px !important;
}

.z-full {
  z-index: 99999999;
}

.min-w-120 {
  min-width: 120px;
}
