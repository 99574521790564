@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon/icomoon.eot?e54b2r');
  src: url('../../assets/fonts/icomoon/icomoon.eot?e54b2r#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon/icomoon.ttf?e54b2r') format('truetype'),
    url('../../assets/fonts/icomoon/icomoon.woff?e54b2r') format('woff'),
    url('../../assets/fonts/icomoon/icomoon.svg?e54b2r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: initial;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-share-line:before {
  content: '\e9ab';
}
.icon-check-circle:before {
  content: '\e9aa';
}
.icon-map-pin-time:before {
  content: '\e9a9';
}
.icon-tag:before {
  content: '\e9a8';
}
.icon-copy-link:before {
  content: '\e9a6';
}
.icon-calendar-multi:before {
  content: '\e9a7';
}
.icon-video-add:before {
  content: '\e9a5';
}
.icon-sip-lin:before {
  content: '\e9a4';
  color: #fff;
}
.icon-design-palette:before {
  content: '\e9a3';
}
.icon-blog:before {
  content: '\e9a0';
}
.icon-instagram:before {
  content: '\e9a1';
}
.icon-youtube:before {
  content: '\e9a2';
}
.icon-ABD .path1:before {
  content: '\e958';
  color: rgb(189, 61, 68);
}
.icon-ABD .path2:before {
  content: '\e959';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ABD .path3:before {
  content: '\e95a';
  margin-left: -1em;
  color: rgb(25, 47, 93);
}
.icon-ABD .path4:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Add:before {
  content: '\e95c';
}
.icon-arrow-right1:before {
  content: '\e95d';
}
.icon-arrow-basic-bottom:before {
  content: '\e95e';
}
.icon-arrow-basic-down:before {
  content: '\e95f';
}
.icon-arrow-basic-left:before {
  content: '\e960';
}
.icon-arrow-basic-right:before {
  content: '\e961';
}
.icon-arrow-basic-top:before {
  content: '\e962';
}
.icon-Back:before {
  content: '\e963';
}
.icon-bill:before {
  content: '\e964';
}
.icon-book-open:before {
  content: '\e965';
}
.icon-Cancel:before {
  content: '\e966';
}
.icon-captcha .path1:before {
  content: '\e967';
  color: rgb(9, 9, 9);
}
.icon-captcha .path2:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(94, 94, 94);
}
.icon-captcha .path3:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(171, 171, 171);
}
.icon-chat-quote:before {
  content: '\e96a';
}
.icon-Check-Full:before {
  content: '\e96b';
}
.icon-Color-drop:before {
  content: '\e96c';
}
.icon-Copy:before {
  content: '\e96d';
}
.icon-database-2:before {
  content: '\e96e';
}
.icon-database:before {
  content: '\e96f';
}
.icon-download:before {
  content: '\e970';
}
.icon-Drupal:before {
  content: '\e971';
  color: #009dde;
}
.icon-edit:before {
  content: '\e972';
}
.icon-eye-close:before {
  content: '\e973';
}
.icon-file-edit:before {
  content: '\e974';
}
.icon-file-text:before {
  content: '\e975';
}
.icon-form-backend1:before {
  content: '\e976';
}
.icon-Google-Cloud .path1:before {
  content: '\e977';
  color: rgb(234, 67, 53);
}
.icon-Google-Cloud .path2:before {
  content: '\e978';
  margin-left: -1em;
  color: rgb(66, 133, 244);
}
.icon-Google-Cloud .path3:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-Google-Cloud .path4:before {
  content: '\e97a';
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-Google-Meet .path1:before {
  content: '\e97b';
  color: rgb(0, 131, 45);
}
.icon-Google-Meet .path2:before {
  content: '\e97c';
  margin-left: -1em;
  color: rgb(0, 102, 218);
}
.icon-Google-Meet .path3:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(233, 66, 53);
}
.icon-Google-Meet .path4:before {
  content: '\e97e';
  margin-left: -1em;
  color: rgb(38, 132, 252);
}
.icon-Google-Meet .path5:before {
  content: '\e97f';
  margin-left: -1em;
  color: rgb(0, 172, 71);
}
.icon-Google-Meet .path6:before {
  content: '\e980';
  margin-left: -1em;
  color: rgb(255, 186, 0);
}
.icon-Google .path1:before {
  content: '\e981';
  color: rgb(66, 133, 244);
}
.icon-Google .path2:before {
  content: '\e982';
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-Google .path3:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-Google .path4:before {
  content: '\e984';
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-grid:before {
  content: '\e985';
}
.icon-heart:before {
  content: '\e986';
}
.icon-links:before {
  content: '\e987';
}
.icon-list:before {
  content: '\e988';
}
.icon-Loading:before {
  content: '\e989';
}
.icon-log-out1:before {
  content: '\e98a';
}
.icon-mastercard .path1:before {
  content: '\e98b';
  color: rgb(255, 95, 0);
}
.icon-mastercard .path2:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.icon-mastercard .path3:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.icon-mastercard .path4:before {
  content: '\e98e';
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.icon-message:before {
  content: '\e98f';
}
.icon-Play:before {
  content: '\e990';
}
.icon-printer:before {
  content: '\e991';
}
.icon-refresh:before {
  content: '\e992';
}
.icon-rocket:before {
  content: '\e993';
}
.icon-save-line-1:before {
  content: '\e994';
}
.icon-Settings:before {
  content: '\e995';
}
.icon-share-1:before {
  content: '\e996';
}
.icon-short-answer1:before {
  content: '\e997';
}
.icon-signature:before {
  content: '\e998';
}
.icon-subtract:before {
  content: '\e999';
}
.icon-team-profile1:before {
  content: '\e99a';
}
.icon-team-user1:before {
  content: '\e99b';
}
.icon-update:before {
  content: '\e99c';
}
.icon-user:before {
  content: '\e99d';
}
.icon-Wherby:before {
  content: '\e99e';
}
.icon-Zoom:before {
  content: '\e99f';
}
.icon-print:before {
  content: '\e957';
  color: #a1a1a1;
}
.icon-add:before {
  content: '\e900';
}
.icon-address:before {
  content: '\e901';
}
.icon-analytic:before {
  content: '\e902';
}
.icon-apple:before {
  content: '\e903';
  color: #b3b3b3;
}
.icon-arrow:before {
  content: '\e904';
}
.icon-arrow-left:before {
  content: '\e905';
}
.icon-arrow-left-right:before {
  content: '\e906';
}
.icon-arrow-right:before {
  content: '\e907';
}
.icon-back:before {
  content: '\e908';
}
.icon-build:before {
  content: '\e909';
}
.icon-calendar:before {
  content: '\e90a';
}
.icon-category:before {
  content: '\e90b';
}
.icon-check:before {
  content: '\e90c';
}
.icon-checkbox:before {
  content: '\e90d';
}
.icon-checkbox-active:before {
  content: '\e90e';
}
.icon-chrome .path1:before {
  content: '\e90f';
  color: rgb(244, 67, 54);
}
.icon-chrome .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-chrome .path3:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(76, 175, 80);
}
.icon-chrome .path4:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(244, 67, 54);
}
.icon-chrome .path5:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-chrome .path6:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-circle-delete:before {
  content: '\e915';
}
.icon-close:before {
  content: '\e916';
}
.icon-color:before {
  content: '\e917';
}
.icon-color-drop:before {
  content: '\e918';
}
.icon-command:before {
  content: '\e919';
}
.icon-computer:before {
  content: '\e91a';
}
.icon-date:before {
  content: '\e91b';
}
.icon-delete:before {
  content: '\e91c';
}
.icon-design:before {
  content: '\e91d';
}
.icon-drag:before {
  content: '\e91e';
}
.icon-dupcliate:before {
  content: '\e91f';
}
.icon-email:before {
  content: '\e920';
}
.icon-eye:before {
  content: '\e921';
}
.icon-facebook:before {
  content: '\e922';
}
.icon-font:before {
  content: '\e923';
}
.icon-fonts:before {
  content: '\e924';
}
.icon-form:before {
  content: '\e925';
}
.icon-form-backend:before {
  content: '\e926';
}
.icon-help:before {
  content: '\e927';
}
.icon-image:before {
  content: '\e928';
}
.icon-integraiton2:before {
  content: '\e929';
}
.icon-integration:before {
  content: '\e92a';
}
.icon-integration3:before {
  content: '\e92b';
}
.icon-laptop:before {
  content: '\e92c';
}
.icon-linkedin:before {
  content: '\e92d';
}
.icon-location:before {
  content: '\e92e';
}
.icon-log-out:before {
  content: '\e92f';
}
.icon-mail:before {
  content: '\e930';
}
.icon-mail-1:before {
  content: '\e931';
}
.icon-membership:before {
  content: '\e932';
}
.icon-more:before {
  content: '\e933';
}
.icon-Next:before {
  content: '\e934';
}
.icon-notificaiton:before {
  content: '\e935';
}
.icon-number:before {
  content: '\e936';
}
.icon-page:before {
  content: '\e937';
}
.icon-payment:before {
  content: '\e938';
}
.icon-payouts:before {
  content: '\e939';
}
.icon-phone:before {
  content: '\e93a';
}
.icon-phone-1:before {
  content: '\e93b';
}
.icon-play:before {
  content: '\e93c';
}
.icon-popup:before {
  content: '\e93d';
}
.icon-privacy:before {
  content: '\e93e';
}
.icon-radio:before {
  content: '\e93f';
}
.icon-radio-active:before {
  content: '\e940';
}
.icon-restore:before {
  content: '\e941';
}
.icon-save-line:before {
  content: '\e942';
}
.icon-screen-recorder:before {
  content: '\e943';
}
.icon-search:before {
  content: '\e944';
}
.icon-settings:before {
  content: '\e945';
}
.icon-share:before {
  content: '\e946';
}
.icon-short-answer:before {
  content: '\e947';
}
.icon-star:before {
  content: '\e948';
}
.icon-stripe:before {
  content: '\e949';
  color: #635bff;
}
.icon-submission:before {
  content: '\e94a';
}
.icon-tablet:before {
  content: '\e94b';
}
.icon-team-profile:before {
  content: '\e94c';
}
.icon-team-user:before {
  content: '\e94d';
}
.icon-text:before {
  content: '\e94e';
}
.icon-time:before {
  content: '\e94f';
}
.icon-tooltip:before {
  content: '\e950';
}
.icon-twitter:before {
  content: '\e951';
}
.icon-url:before {
  content: '\e952';
}
.icon-video:before {
  content: '\e953';
}
.icon-wallet:before {
  content: '\e954';
}
.icon-website:before {
  content: '\e955';
}
.icon-action:before {
  content: '\e956';
}
