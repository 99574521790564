.copy_input {
  height: 40px;
  border-radius: 3px;
  input {
    // width: 230px;
  }
  a {
    span {
      padding: 12px;
    }
  }
  button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 100%;
  }
}
