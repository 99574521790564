.primary_tabs {
  @apply space-x-4 flex items-center mb-4 relative;

  &::after {
    content: '';
    height: 1px;
    background-color: var(--bg-gray-10);
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .primary_tab {
    @apply border-b-2 border-transparent cursor-pointer pb-2 text-cp-gray-40;
    &.active {
      @apply border-cp-primary-100 text-cp-gray-100;
    }
  }
}
