:global {
  .step-header {
    border-bottom: 1px solid var(--bg-gray-10);
  }

  .step-bottom {
    border-top: 1px solid var(--bg-gray-10);
  }
}

.header {
  // @apply fixed w-full flex items-center justify-between;
  // height: 65px;
  // position: relative;
  z-index: 20;
  // padding-right: 20px;

  @screen lg {
    padding: 0;
  }

  .logo_box {
    width: 30px;
    height: 32px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    img {
      display: none;
      // width: 40px;
      // height: 40px;
      width: 40px;
      height: 40px;
      margin: auto;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
    }
    @screen lg {
      width: 60px;
      height: 64px;
      img {
        display: block;
      }
      span {
        opacity: 0;
      }
      &:hover {
        span {
          opacity: 1;
        }
        img {
          opacity: 0;
        }
      }
    }
  }
  .nav_item {
    @apply flex items-center cursor-pointer;
    color: #a1a1a1;

    svg {
      max-width: 18px;
    }

    span {
      margin-left: 5px;
      &:first-child {
        margin-left: 0px;
      }
    }

    &.active_nav {
      color: #000000;

      span {
        font-weight: 500;
      }

      svg path {
        fill: #000000;
      }
    }
  }
}
