.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  .delete-modal-center {
    position: relative;
    @apply bg-cp-white dark:bg-cp-gray-10;

    padding: 30px 20px 20px;
    border-radius: 6px;
    max-width: 360px;
    width: 100%;
    margin: 1.5rem auto;
  }
  .delete-modal-content {
    width: 100%;
  }
  .delete-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.preview-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  .preview-modal-center {
    position: relative;
    @apply bg-cp-white dark:bg-cp-gray-10;

    border-radius: 6px;
    max-width: 960px;
    width: 100%;
    overflow: hidden;
    margin: 1.5rem auto;
  }
  .preview-modal-content {
    width: 100%;
    .preview-header {
      padding: 20px;
    }
  }
  .preview-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transform: scale(0.6);
  }
}

.code-mirror-height {
  .CodeMirror {
    height: 600px !important;
    font-size: 13px;
  }
}

.verify-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  .verify-modal-center {
    position: relative;
    @apply bg-cp-white dark:bg-cp-gray-10;

    padding: 30px 20px 20px;
    border-radius: 6px;
    max-width: 620px;
    width: 100%;
    margin: 1.5rem auto;
  }
  .verify-modal-content {
    width: 100%;
  }
  .verify-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.downgrade-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  .downgrade-modal-center {
    position: relative;
    @apply bg-cp-white dark:bg-cp-gray-10;

    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .downgrade-modal-content {
    width: 100%;
  }
  .downgrade-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  &:focus-visible {
    outline: none !important;
  }
}

.medium-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  .medium-modal-center {
    @apply bg-cp-white dark:bg-cp-gray-10;
    position: relative;
    border-radius: 6px;
    max-width: 640px;
    width: 100%;
    // border: 1px solid var(--bg-gray-10);
    margin: 1.5rem auto;
  }

  .medium-modal-content {
    width: 100%;

    .medium-modal-title {
      padding: 36px 32px 19px 31px;
    }
    .medium-modal-body {
      padding: 0 36px 40px;
    }
    .medium-modal-footer {
      padding: 12px 8px 8px 24px;
      min-height: 32px;
      height: 100%;
      @apply bg-cp-gray-0 dark:bg-cp-gray-20;
      // border: 1px solid var(--bg-gray-10);
      box-sizing: border-box;
      border-radius: 0px 0px 6px 6px;
    }
  }
  .medium-modal-close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
}
.small-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  .small-modal-center {
    @apply bg-cp-white dark:bg-cp-gray-10;
    position: relative;
    border-radius: 6px;
    max-width: 420px;
    width: 100%;
    // border: 1px solid var(--bg-gray-10);
    margin: 1.5rem auto;
  }
  .small-modal-content {
    width: 100%;

    .small-modal-title {
      padding: 36px 32px 19px 31px;
    }
    .small-modal-body {
      padding: 0 36px 40px;
    }
    .small-modal-footer {
      padding: 12px 8px 8px 24px;
      min-height: 32px;
      height: 100%;
      @apply bg-cp-gray-0 dark:bg-cp-gray-20;
      // border: 1px solid var(--bg-gray-10);
      box-sizing: border-box;
      border-radius: 0px 0px 6px 6px;
    }
  }
  .small-modal-close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
}

.large-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 110;
  overflow-x: hidden;
  overflow-y: auto;

  .large-modal-center {
    position: relative;
    @apply bg-cp-white dark:bg-cp-gray-10;

    border-radius: 6px;
    max-width: 752px;
    width: 100%;
    // border: 1px solid var(--bg-gray-10);
    margin: 1.5rem auto;
  }
  .large-modal-content {
    width: 100%;
  }
  .large-modal-title {
    padding: 36px 32px 19px 31px;
  }
  .large-modal-body {
    padding: 0 36px 40px;
  }
  .large-modal-footer {
    padding: 12px 8px 8px 24px;
    min-height: 32px;
    height: 100%;
    @apply bg-cp-gray-0 dark:bg-cp-gray-20;
    // border: 1px solid var(--bg-gray-10);
    box-sizing: border-box;
    border-radius: 0px 0px 6px 6px;
  }
  .large-modal-close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
}

.video-record-content {
  position: relative;
  border-radius: 6px;
  width: calc(100% - 64px);
  // max-width: 460px;
  max-height: calc(100% - 64px);
  // height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 6px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    border-radius: 6px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
    i {
      color: white;
    }
  }

  .video-record-play-area {
    .video-control {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      .control-button {
        background-color: rgba(0, 0, 0, 0.2);
        padding: 6px;
        z-index: 10;
        cursor: pointer;
        display: flex;
        border-radius: 6px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
        i {
          color: white;
        }
      }
    }
  }
}
