.avatar-group {
  @apply flex items-center -space-x-2;
}

.avatar {
  @apply block overflow-hidden rounded-full border border-cp-gray-20 bg-cp-white;

  &.avatar-default {
    @apply w-6 h-6;
  }
}
