.box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .preview_icon {
    @apply ring-4 ring-white bg-cp-white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -20px;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    border: 2px solid #e4e4e4;
    svg {
      transform: scale(0.8);
    }

    &:hover {
      background: #f2b02f;
      border: 2px solid #f2b02f;

      svg path {
        fill: #fff;
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    // background: black;
    color: white;
    padding: 8px 10px 9px 28px;
    border-radius: 3px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 44px;
      height: 44px;
      background: var(--bg-gray-0);
      border-radius: 50%;
      left: -22px;
    }

    .left_icon {
      font-size: 13px;
    }
    .right_icon {
      font-size: 13px;
    }
  }

  // .droprown {
  //   position: absolute;
  //   background: black;
  //   border-radius: 3px;
  //   color: white;
  //   top: calc(100% + 6px);
  //   right: 0;
  //   padding: 18px 20px;
  //   min-width: max-content;
  //   display: none;
  //   &.active {
  //     display: block;
  //   }
  //   ul {
  //     li {
  //       color: #a1a1a1;
  //       &:hover {
  //         color: white;
  //         cursor: pointer;
  //       }
  //     }
  //   }
  // }

  .droprown {
    position: absolute;
    background: var(--bg-gray-0);
    box-shadow: 0px 10px 30px rgba(34, 34, 34, 0.05);
    border-radius: 3px;
    color: rgb(0, 0, 0);
    top: calc(100% + 8px);
    right: 10px;
    padding: 20px;
    min-width: 400px;
    cursor: default;
    display: none;
    border: 1px solid var(--bg-gray-10);
    &.active {
      display: block;
    }
    ul {
      li {
        .icon_box {
          display: inline-block;
          // padding: 32px;
          background: var(--bg-gray-10);
          border-radius: 3px;
          margin-top: 16px;
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1/1;

          svg {
            margin: 0;
            display: inline-block;
          }
          // &.active {
          //   background: #fef7ea;
          // }
        }
      }
    }
  }
}
