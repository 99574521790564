.view_area {
  display: flex;
  height: calc(100vh - 64px);
}

.view {
  width: 100%;
  height: calc(100vh - 76px - 64px);
  background: var(--bg-gray-0);
  margin: auto;
  border-radius: 16px;
  border: 12px solid black;
  position: relative;

  &.pc_view {
    max-width: 1920px;
    max-height: 720px;
  }
  &.laptop_view {
    max-width: 1210px;
    max-height: 690px;
  }
  &.tablet_view {
    max-width: 550px;
    max-height: 660px;
  }

  &.tablet_view {
    border-bottom-width: 36px;
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--bg-gray-0);
      bottom: -18px;
      left: calc(50%);
      transform: translate(-50%, 50%);
    }
  }
  &.phone_view {
    max-width: 415px;
    max-height: 707px;
    border-top-width: 36px;
    border-bottom-width: 36px;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--bg-gray-0);
      top: -22px;
      left: calc(50%);
      transform: translateX(-50%);
    }
  }

  &.laptop_view,
  &.pc_view {
    &:after {
      content: '';
      position: absolute;
      background: black;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }
  &.laptop_view {
    &:after {
      width: calc(100% + 72px);
      height: 40px;
      border-bottom-right-radius: 100%;
      border-bottom-left-radius: 100%;
    }
    // &:before {
    //   content: '';
    //   position: absolute;
    //   width: 12px;
    //   height: 12px;
    //   border-radius: 50%;
    //   background: black;
    //   top: 10px;
    //   left: calc(50%);
    //   transform: translateX(-50%);
    // }
  }
  &.pc_view {
    &:after {
      width: 16%;
      height: 36px;
    }
  }
}
